@media print {
    .printable-content {
      margin: 20mm; /* Define as margens ao redor do conteúdo impresso */
      position: relative;
    }
  
    .print-header,
    .print-footer {
      position: fixed;
      width: 100%;
      left: 0;
      right: 0;
      text-align: center;
    }
  
    .print-header {
      top: 0;
      margin-bottom: 2mm;
    }
  
    .print-footer {
      bottom: 0;
    }
  
    .print-body {
      
      margin-top: 50mm; /* Espaço para o cabeçalho */
      margin-bottom: 50mm; /* Espaço para o rodapé */
    }
  
    
  }
  