
.overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: #fefcf4;
    
    background-image: url("../../data/images/login_background.jpg");  
    backdrop-filter: sepia(100%);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: 100% 100%;
    z-index: 10;
    
  }